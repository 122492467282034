<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
import _ from "lodash";

export default {
  page: {
    title: "Report Jadwal",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Datatable,
    Pagination,
  },
  data() {
    let columns = [
      { width: "50px", label: "No", name: "-" },
      { width: "auto", label: "ID Jadwal", name: "-" },
      { width: "auto", label: "Keterangan", name: "-" },
      { width: "auto", label: "Batch", name: "-" },
      { width: "auto", label: "Waktu Mulai", name: "-" },
      { width: "auto", label: "Waktu Selesai", name: "-" },
      { width: "auto", label: "NIP", name: "-" },
      { width: "auto", label: "Peserta", name: "-" },
      { width: "auto", label: "Jabatan Asesmen", name: "-" },
      { width: "auto", label: "Unit", name: "-" },
      { width: "auto", label: "Hasil", name: "-" },
    ];
    // Get hak akses
    const menu = "Report Jadwal";

    return {
      title: menu,
      isWrite: localStorage.getItem("isWrite"),
      isRead: localStorage.getItem("isRead"),
      isLoading: true,
      columns: columns,
      tableData: [],
      tableFilter: {
        page: 1,
        per_page: 10,
        nip: "",
        nama: "",
        status: 1,
        idjadwal: "",
        nama_batch: "",
        jabatan: "",
        kompetensi: "",
      },
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
      filterJadwal: null,
      filterBatch: null,
      filterJabatan: null,
      filterKompetensi: null,
      filterNama: null,
      filterNip: null,
      optionFilterJadwal: [],
      optionFilterBatch: [],
      optionFilterJabatan: [],
      optionFilterKompetensi: [],
      optionFilterNama: [],
      optionFilterNip: [],
    };
  },
  async mounted() {
    await this.getData();
    this.getFilterJadwal();
    this.getFilterBatch();
    this.getFilterJabatan();
    this.getFilterKompetensi();
    this.getFilterNama();
    this.getFilterNip();

    this.filterJadwal = _.debounce(this.searchJadwal.bind(this), 500);
    this.filterBatch = _.debounce(this.searchBatch.bind(this), 500);
    this.filterJabatan = _.debounce(this.searchJabatanKompetensi.bind(this), 500);
    this.filterKompetensi = _.debounce(this.searchKompetensi.bind(this), 500);
    this.filterNama = _.debounce(this.searchNama.bind(this), 500);
    this.filterNip = _.debounce(this.searchNip.bind(this), 500);
  },
  methods: {
    async getData() {
      this.isLoading = true;
      const data = await axios({
        methods: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "report-jadwal",
        headers: {
          Authorization: "Bearer " + localStorage.access_token,
        },
        params: this.tableFilter,
      })
        .then((response) => response.data)
        .catch((err) => {
          let errResponse = err.response.data;
          if (errResponse.meta?.code == 403) {
            Swal.fire({
              icon: "error",
              title: "Terjadi Kesalahan",
              text: errResponse.meta.message,
            });
            this.$router.push({ name: "logout" });
            return false;
          }
          Swal.fire({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: errResponse.data,
          });

          console.log(errResponse);
          return false;
        });
      if (data) {
        if (data.meta.code == 403) {
          this.$router.push("logout");
        } else if (data.meta.code == 200) {
          this.tableData = data.data.data;
          this.pagination.lastPage = data.data.last_page;
          this.pagination.currentPage = data.data.current_page;
          this.pagination.total = data.data.total;
          this.pagination.lastPageUrl = data.data.last_page_url;
          this.pagination.nextPageUrl = data.data.next_page_url;
          this.pagination.prevPageUrl = data.data.prev_page_url;
          this.pagination.from = data.data.from;
          this.pagination.to = data.data.to;
          this.pagination.links = data.data.links;
        }
      }
      this.isLoading = false;
    },
    toPage(url) {
      let link = new URLSearchParams(url);
      this.tableFilter.page = link.get("page");
      // this.tableFilter.per_page = link.get('per_page');
      this.getData();
    },
    async searchJadwal(search, loading) {
      if (search.length) {
        loading(true);
        this.getFilterJadwal(search, loading);
      }
    },
    async getFilterJadwal(search = "", loading) {
      await axios
        .get(process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/jadwal/all", {
          params: {
            search: search,
            limit: 10,
          },
          headers: {
            Authorization: "Bearer " + localStorage.access_token,
          },
        })
        .then((response) => {
          this.optionFilterJadwal = response.data.data;
          if (loading) loading(false);
        });
    },
    async searchBatch(search, loading) {
      if (search.length) {
        loading(true);
        this.getFilterBatch(search, loading);
      }
    },
    async getFilterBatch(search = "", loading) {
      await axios({
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "referensi/jadwal-batch/all",
        params: {
          search: search,
          limit: 10,
        },
        headers: {
          Authorization: "Bearer " + localStorage.access_token,
        },
      }).then((response) => {
        this.optionFilterBatch = response.data.data;
        if (loading) loading(false);
      });
    },
    async searchJabatanKompetensi(search, loading) {
      if (search.length) {
        loading(true);
        this.getFilterJabatan(search, loading);
      }
    },
    async getFilterJabatan(search = "", loading) {
      await axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "referensi/jabatan-kompetensi/all",
          {
            params: {
              search: search,
              limit: 10,
            },
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
          }
        )
        .then((response) => {
          this.optionFilterJabatan = response.data.data;
          if (loading) loading(false);
        });
    },
    async searchKompetensi(search, loading) {
      if (search.length) {
        loading(true);
        this.getFilterKompetensi(search, loading);
      }
    },
    async getFilterKompetensi(search = "", loading) {
      await axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/kompetensi/all",
          {
            params: {
              search: search,
              limit: 10,
            },
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
          }
        )
        .then((response) => {
          this.optionFilterKompetensi = response.data.data;
          if (loading) loading(false);
        });
    },
    async searchNama(search, loading) {
      if (search.length) {
        loading(true);
        this.getFilterNama(search, loading);
      }
    },
    async getFilterNama(search = "", loading) {
      await axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "referensi/report-jadwal/all",
          {
            params: {
              search: search,
              limit: 10,
              col: "nama",
            },
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
          }
        )
        .then((response) => {
          this.optionFilterNama = response.data.data;
          if (loading) loading(false);
        });
    },
    async searchNip(search, loading) {
      if (search.length) {
        loading(true);
        this.getFilterNip(search, loading);
      }
    },
    async getFilterNip(search = "", loading) {
      await axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "referensi/report-jadwal/all",
          {
            params: {
              search: search,
              limit: 10,
              col: "nip",
            },
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
          }
        )
        .then((response) => {
          this.optionFilterNip = response.data.data;
          if (loading) loading(false);
        });
    },
    exportData() {
      let params = new URLSearchParams(this.tableFilter).toString();
      window.open(
        process.env.VUE_APP_BACKEND_URL_VERSION +
          "export-excel/report-jadwal?" +
          params
      );
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body row">
            <div class="col-12">
              <div class="row">
                <div class="d-flex justify-content-between">
                  <div class="card-title">Report Jadwal Perencanaan</div>
                  <button
                    class="btn btn-sm btn-success"
                    @click="exportData()"
                    v-if="isWrite == 1"
                  >
                    Export Report Peserta
                  </button>
                </div>
                <div class="col-md-12">
                  <hr />
                  <div class="row">
                    <div class="col-lg">
                      <label for="filterJadwal">Jadwal</label>
                      <v-select
                        id="parent_menu"
                        class="style-chooser"
                        :options="optionFilterJadwal"
                        :reduce="(jadwal) => jadwal.idjadwal"
                        label="kode_tes"
                        v-model="tableFilter.idjadwal"
                        placeholder="Filter Jadwal"
                        @search="filterJadwal"
                        @update:modelValue="getData"
                      >
                      </v-select>
                    </div>
                    <div class="col-lg">
                      <label for="filterBatch">Batch</label>
                      <v-select
                        id="parent_menu"
                        class="style-chooser"
                        :options="optionFilterBatch"
                        :reduce="(batch) => batch.nama_batch"
                        label="nama_batch"
                        v-model="tableFilter.nama_batch"
                        placeholder="Filter Batch"
                        @search="filterBatch"
                        @update:modelValue="getData"
                      >
                      </v-select>
                    </div>
                    <div class="col-lg">
                      <label for="filterJabatan">Jabatan</label>
                      <v-select
                        id="parent_menu"
                        class="style-chooser"
                        :options="optionFilterJabatan"
                        :reduce="(jabatan) => jabatan.nama_jabatan"
                        label="nama_jabatan"
                        v-model="tableFilter.jabatan"
                        placeholder="Filter Jabatan"
                        @search="filterJabatan"
                        @update:modelValue="getData"
                      >
                      </v-select>
                    </div>
                    <div class="col-lg">
                      <label for="filterNama">Nama</label>
                      <v-select
                        id="filterNama"
                        class="style-chooser"
                        :options="optionFilterNama"
                        :reduce="(item) => item.nama"
                        label="nama"
                        v-model="tableFilter.nama"
                        placeholder="Filter Nama"
                        @search="filterNama"
                        @update:modelValue="getData"
                      />
                    </div>
                    <div class="col-lg">
                      <label for="FilterNIP">NIP</label>
                      <v-select
                        id="filterNIP"
                        class="style-chooser"
                        :options="optionFilterNip"
                        :reduce="(item) => item.nip"
                        label="nip"
                        v-model="tableFilter.nip"
                        placeholder="Filter NIP"
                        @search="filterNip"
                        @update:modelValue="getData"
                      />
                    </div>
                    <!-- <div class="p-2 mt-3">
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="getData()"
                      >
                        Filter
                      </button>
                    </div> -->
                  </div>
                  <hr />
                </div>
                <div class="col-md-12 mb-2">
                  <div class="row">
                    <div class="col-md-4">
                      <table>
                        <tr>
                          <td>Show</td>
                          <td>
                            <select
                              class="form-control form-control-sm"
                              id="showpaginatetable"
                              style="width: 100%"
                              v-model="tableFilter.per_page"
                              @change="getData"
                            >
                              <option value="10" selected>10</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              <option value="200">200</option>
                              <option value="500">500</option>
                            </select>
                          </td>
                          <td>Entries</td>
                        </tr>
                      </table>
                    </div>
                    <div class="col-md-5">&nbsp;</div>
                    <div class="col-md-3">
                      <!-- <input
                      type="text"
                      class="form-control form-control-sm"
                      id="searchTable"
                      placeholder="Cari Data ..."
                      v-model="tableData.search"
                      @input="getDataTable()"
                    /> -->
                    </div>
                  </div>
                </div>
                <datatable :columns="columns">
                  <tbody>
                    <tr v-if="isLoading">
                      <td colspan="15" class="text-center">
                        <i class="fas fa-spinner fa-spin"></i> Loading...
                      </td>
                    </tr>
                    <tr v-else-if="tableData == ''">
                      <td class="text-center" colspan="99">
                        Data Tidak Tersedia
                      </td>
                    </tr>
                    <tr v-else v-for="(row, index) in tableData" :key="index">
                      <td class="text-center">
                        {{ index + pagination.from }}
                      </td>
                      <td class="text-center">
                        {{ row.idjadwal }}
                      </td>
                      <td>
                        {{ row.keterangan }}
                      </td>
                      <td>
                        {{ row.nama_batch }}
                      </td>
                      <td>
                        {{ row.waktu_mulai }}
                      </td>
                      <td>
                        {{ row.waktu_selesai }}
                      </td>
                      <td>
                        {{ row.nip }}
                      </td>
                      <td>
                        {{ row.nama }}
                      </td>
                      <td>
                        {{ row.jabatan_asesmen }}
                      </td>
                      <td>
                        {{ row.unit }}
                      </td>
                      <td>
                        {{ row.nilai_text || "-" }}
                      </td>
                    </tr>
                  </tbody>
                </datatable>
              </div>
              <pagination
                :pagination="pagination"
                @to-page="toPage"
              ></pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped></style>
